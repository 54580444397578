export const LOGIN_ARTISAN_REQUEST = "LOGIN_ARTISAN_REQUEST";
export const LOGIN_ARTISAN_SUCCESS = "LOGIN_ARTISAN_SUCCESS";
export const LOGIN_ARTISAN_FAIL = "LOGIN_ARTISAN_FAIL";

export const REGISTER_ARTISAN_REQUEST = "REGISTER_ARTISAN_REQUEST";
export const REGISTER_ARTISAN_SUCCESS = "REGISTER_ARTISAN_SUCCESS";
export const REGISTER_ARTISAN_FAIL = "REGISTER_ARTISAN_FAIL";

export const SAVE_CONTACT_INFO = "SAVE_CONTACT_INFO";
export const SAVE_PERSONAL_INFO = "SAVE_PERSONAL_INFO";

export const LOAD_ARTISAN_REQUEST = "LOAD_ARTISAN_REQUEST";
export const LOAD_ARTISAN_SUCCESS = "LOAD_ARTISAN_SUCCESS";
export const LOAD_ARTISAN_FAIL = "LOAD_ARTISAN_FAIL";

export const LOGOUT_ARTISAN_SUCCESS = "LOGOUT_ARTISAN_SUCCESS";
export const LOGOUT_ARTISAN_FAIL = "LOGOUT_ARTISAN_FAIL";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const UPDATE_ARTISAN_REQUEST = "UPDATE_ARTISAN_REQUEST";
export const UPDATE_ARTISAN_SUCCESS = "UPDATE_ARTISAN_SUCCESS";
export const UPDATE_ARTISAN_RESET = "UPDATE_ARTISAN_RESET";
export const UPDATE_ARTISAN_FAIL = "UPDATE_ARTISAN_FAIL";

export const DELETE_ARTISAN_REQUEST = "DELETE_ARTISAN_REQUEST";
export const DELETE_ARTISAN_SUCCESS = "DELETE_ARTISAN_SUCCESS";
export const DELETE_ARTISAN_RESET = "DELETE_ARTISAN_RESET";
export const DELETE_ARTISAN_FAIL = "DELETE_ARTISAN_FAIL";

export const ALL_ARTISANS_REQUEST = "ALL_ARTISANS_REQUEST";
export const ALL_ARTISANS_SUCCESS = "ALL_ARTISANS_SUCCESS";
export const ALL_ARTISANS_FAIL = "ALL_ARTISANS_FAIL";

export const ARTISAN_DETAILS_REQUEST = "ARTISAN_DETAILS_REQUEST";
export const ARTISAN_DETAILS_SUCCESS = "ARTISAN_DETAILS_SUCCESS";
export const ARTISAN_DETAILS_FAIL = "ARTISAN_DETAILS_FAIL";

export const NEW_PASSWORD_REQUEST = "NEW_PASSWORD_REQUEST";
export const NEW_PASSWORD_SUCCESS = "NEW_PASSWORD_SUCCESS";
export const NEW_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const PREFS_STATES_REQUEST = "PREFS_STATES_REQUEST";
export const PREFS_STATES_SUCCESS = "PREFS_STATES_SUCCESS";
export const PREFS_STATES_FAIL = "PREFS_STATES_FAIL";

export const PREFS_LGAS_REQUEST = "PREFS_LGAS_REQUEST";
export const PREFS_LGAS_SUCCESS = "PREFS_LGAS_SUCCESS";
export const PREFS_LGAS_FAIL = "PREFS_LGAS_FAIL";

export const PREFS_TOWNS_REQUEST = "PREFS_TOWNS_REQUEST";
export const PREFS_TOWNS_SUCCESS = "PREFS_TOWNS_SUCCESS";
export const PREFS_TOWNS_FAIL = "PREFS_TOWNS_FAIL";

export const WORKERS_CATEGORIES_REQUEST = "WORKERS_CATEGORIES_REQUEST";
export const WORKERS_CATEGORIES_SUCCESS = "WORKERS_CATEGORIES_SUCCESS";
export const WORKERS_CATEGORIES_FAIL = "WORKERS_CATEGORIES_FAIL";
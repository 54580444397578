
export const ALL_WORKERS_REQUEST = 'ALL_WORKERS_REQUEST'
export const ALL_WORKERS_SUCCESS = 'ALL_WORKERS_SUCCESS'
export const ALL_WORKERS_FAIL = 'ALL_WORKERS_FAIL'

export const WORKERS_SETUP_REQUEST = 'WORKERS_SETUP_REQUEST'
export const WORKERS_SETUP_SUCCESS = 'WORKERS_SETUP_SUCCESS'
export const WORKERS_SETUP_FAIL = 'WORKERS_SETUP_FAIL'

export const LOAD_USER_WORKERS_REQUEST = 'LOAD_USER_WORKERS_REQUEST'
export const LOAD_USER_WORKERS_SUCCESS = 'LOAD_USER_WORKERS_SUCCESS'
export const LOAD_USER_WORKERS_FAIL = 'LOAD_USER_WORKERS_FAIL'

export const WORKER_DETAILS_REQUEST = 'WORKER_DETAILS_REQUEST'
export const WORKER_DETAILS_SUCCESS = 'WORKER_DETAILS_SUCCESS'
export const WORKER_DETAILS_FAIL = 'WORKER_DETAILS_FAIL'

export const WORKER_REVIEW_REQUEST = 'WORKER_REVIEW_REQUEST'
export const WORKER_REVIEW_SUCCESS = 'WORKER_REVIEW_SUCCESS'
export const WORKER_REVIEW_FAIL = 'WORKER_REVIEW_FAIL'
export const WORKER_REVIEW_RESET= 'WORKER_REVIEW_RESET'

export const CREATE_WORKER_REQUEST = 'CREATE_WORKER_REQUEST'
export const CREATE_WORKER_SUCCESS = 'CREATE_WORKER_SUCCESS'
export const CREATE_WORKER_FAIL = 'CREATE_WORKER_FAIL'
export const CREATE_WORKER_RESET = 'CREATE_WORKER_RESET'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

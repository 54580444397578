export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL'
export const REGISTER_USER_RESET = 'REGISTER_USER_RESET'

export const REGISTER_AGENT_REQUEST = 'REGISTER_AGENT_REQUEST'
export const REGISTER_AGENT_SUCCESS = 'REGISTER_AGENT_SUCCESS'
export const REGISTER_AGENT_FAIL = 'REGISTER_AGENT_FAIL'
export const REGISTER_AGENT_RESET = 'REGISTER_AGENT_RESET'

export const ACTIVATION_LINK_REQUEST = 'ACTIVATION_LINK_REQUEST'
export const ACTIVATION_LINK_SUCCESS = 'ACTIVATION_LINK_SUCCESS'
export const ACTIVATION_LINK_FAIL = 'ACTIVATION_LINK_FAIL'

export const ACTIVATE_ACCOUNT_REQUEST = 'ACTIVATE_ACCOUNT_REQUEST'
export const ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS'
export const ACTIVATE_ACCOUNT_FAIL = 'ACTIVATE_ACCOUNT_FAIL'

export const SAVE_CONTACT_INFO = 'SAVE_CONTACT_INFO'
export const SAVE_PERSONAL_INFO = 'SAVE_PERSONAL_INFO'

export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST'
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const LOAD_USER_FAIL = 'LOAD_USER_FAIL'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_RESET = 'UPDATE_PROFILE_RESET'
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_RESET = 'UPDATE_PASSWORD_RESET'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'

export const WALLET_BALANCE_REQUEST = 'WALLET_BALANCE_REQUEST'
export const WALLET_BALANCE_SUCCESS = 'WALLET_BALANCE_SUCCESS'
export const WALLET_BALANCE_FAIL = 'WALLET_BALANCE_FAIL'

export const WALLET_TRANSACTIONS_REQUEST = 'WALLET_TRANSACTIONS_REQUEST'
export const WALLET_TRANSACTIONS_SUCCESS = 'WALLET_TRANSACTIONS_SUCCESS'
export const WALLET_TRANSACTIONS_FAIL = 'WALLET_TRANSACTIONS_FAIL'

export const CHANGE_USER_MODE_REQUEST = 'CHANGE_USER_MODE_REQUEST'
export const CHANGE_USER_MODE_SUCCESS = 'CHANGE_USER_MODE_SUCCESS'
export const CHANGE_USER_MODE_FAIL = 'CHANGE_USER_MODE_FAIL'

export const WALLET_TOPUP_REQUEST = 'WALLET_TOPUP_REQUEST'
export const WALLET_TOPUP_SUCCESS = 'WALLET_TOPUP_SUCCESS'
export const WALLET_TOPUP_VERIFY = 'WALLET_TOPUP_VERIFY'
export const WALLET_TOPUP_LINK = 'WALLET_TOPUP_LINK'
export const WALLET_TOPUP_LINK_RESET = 'WALLET_TOPUP_LINK_RESET'
export const WALLET_TOPUP_FAIL = 'WALLET_TOPUP_FAIL'
export const WALLET_TOPUP_RESET = 'WALLET_TOPUP_RESET'

export const WALLET_WITHDRAWAL_REQUEST = 'WALLET_WITHDRAWAL_REQUEST'
export const WALLET_WITHDRAWAL_SUCCESS = 'WALLET_WITHDRAWAL_SUCCESS'
export const WALLET_WITHDRAWAL_FAIL = 'WALLET_WITHDRAWAL_FAIL'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_RESET = 'UPDATE_USER_RESET'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_RESET = 'DELETE_USER_RESET'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'

export const ALL_USERS_REQUEST = 'ALL_USERS_REQUEST'
export const ALL_USERS_SUCCESS = 'ALL_USERS_SUCCESS'
export const ALL_USERS_FAIL = 'ALL_USERS_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'

export const NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST'
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS'
export const NEW_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'


export const CLEAR_ERRORS = 'CLEAR_ERRORS'
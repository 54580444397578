export const ALL_TASKS_REQUEST = "ALL_TASKS_REQUEST";
export const ALL_TASKS_SUCCESS = "ALL_TASKS_SUCCESS";
export const ALL_TASKS_FAIL = "ALL_TASKS_FAIL";

export const MY_TASKS_REQUEST = "MY_TASKS_REQUEST";
export const MY_TASKS_SUCCESS = "MY_TASKS_SUCCESS";
export const MY_WORKS_SUCCESS = "MY_WORKS_SUCCESS";
export const MY_TASKS_FAIL = "MY_TASKS_FAIL";

export const TASKS_DETAILS_REQUEST = "TASKS_DETAILS_REQUEST";
export const TASKS_DETAILS_SUCCESS = "TASKS_DETAILS_SUCCESS";
export const TASKS_DETAILS_FAIL = "TASKS_DETAILS_FAIL";

export const NEW_TASK_REQUEST = "NEW_TASK_REQUEST";
export const NEW_TASK_SUCCESS = "NEW_TASK_SUCCESS";
export const NEW_TASK_RESET = "NEW_TASK_RESET";
export const NEW_TASK_FAIL = "NEW_TASK_FAIL";

export const DELETE_TASK_REQUEST = "DELETE_TASK_REQUEST";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_RESET = "DELETE_TASK_RESET";
export const DELETE_TASK_FAIL = "DELETE_TASK_FAIL";

export const UPDATE_TASK_REQUEST = "UPDATE_TASK_REQUEST";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_RESET = "UPDATE_TASK_RESET";
export const UPDATE_TASK_FAIL = "UPDATE_TASK_FAIL";

export const UPDATE_TASK_PROGRESS_REQUEST = "UPDATE_TASK_PROGRESS_REQUEST";
export const UPDATE_TASK_PROGRESS_SUCCESS = "UPDATE_TASK_PROGRESS_SUCCESS";
export const UPDATE_TASK_PROGRESS_RESET = "UPDATE_TASK_PROGRESS_RESET";
export const UPDATE_TASK_PROGRESS_FAIL = "UPDATE_TASK_PROGRESS_FAIL";

export const CREATE_TASK_REQUEST = "CREATE_TASK_REQUEST";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_RESET = "CREATE_TASK_RESET";
export const CREATE_TASK_FAIL = "CREATE_TASK_FAIL";

export const NEARBY_TASK_REQUEST = "NEARBY_TASK_REQUEST";
export const NEARBY_TASK_SUCCESS = "NEARBY_TASK_SUCCESS";
export const NEARBY_TASK_FAIL = "NEARBY_TASK_FAIL";

export const NEW_REVIEW_REQUEST = "NEW_REVIEW_REQUEST";
export const NEW_REVIEW_SUCCESS = "NEW_REVIEW_SUCCESS";
export const NEW_REVIEW_RESET = "NEW_REVIEW_RESET";
export const NEW_REVIEW_FAIL = "NEW_REVIEW_FAIL";

export const TASK_WORKER_APPLICATION_REQUEST = "TASK_WORKER_APPLICATION_REQUEST";
export const TASK_WORKER_APPLICATION_SUCCESS = "TASK_WORKER_APPLICATION_SUCCESS";
export const TASK_WORKER_APPLICATION_RESET = "TASK_WORKER_APPLICATION_RESET";
export const TASK_WORKER_APPLICATION_FAIL = "TASK_WORKER_APPLICATION_FAIL";

export const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_RESET = "DELETE_REVIEW_RESET";
export const DELETE_REVIEW_FAIL = "DELETE_REVIEW_FAIL";

export const ADMIN_TASKS_REQUEST = "ADMIN_TASKS_REQUEST";
export const ADMIN_TASKS_SUCCESS = "ADMIN_TASKS_SUCCESS";
export const ADMIN_TASKS_FAIL = "ADMIN_TASKS_FAIL";

export const GET_REVIEWS_REQUEST = "GET_REVIEWS_REQUEST";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAIL = "GET_REVIEWS_FAIL";
export const GET_REVIEWS_RESET = "GET_REVIEWS_RESET";

export const REVIEW_REQUEST = "REVIEW_REQUEST";
export const REVIEW_SUCCESS = "REVIEW_SUCCESS";
export const REVIEW_RESET = "REVIEW_RESET";
export const REVIEW_FAIL = "REVIEW_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
